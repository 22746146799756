import Image from 'next/image';
import React from 'react';
import heroImage from '../public/minh-pham-lB9ylP8e9Sg-unsplash.jpg';
import { ClientOnlySuspense, ErrorBoundary } from '@santicon/web/shared/utils';
import dynamic from 'next/dynamic';

const SignInAndRegisterButtons = dynamic(
  () =>
    import('@santicon/web/aherrus/navigation').then(
      mod => mod.SignInAndRegisterButtons,
    ),
  { ssr: false },
);

export function Index() {
  return (
    <div className="mb-8 flex flex-col items-center gap-8 px-4 pt-8 sm:px-0">
      <section className="flex flex-col gap-8">
        <h1 className="font-heading text-4xl font-extrabold drop-shadow">
          Welcome to Aherrus work tracking
        </h1>
        <div className="flex flex-wrap-reverse items-center gap-8">
          <div className="w-64 flex-auto text-left">
            <h2 className="font-heading text-2xl font-bold">
              Location based work tracking
            </h2>
            <p>
              Aherrus is work tracking application for mobile and web. You can
              track your work by using work or break sessions. With mobile
              application you can track your work by using your phone. You can
              use GPS location to track your work. With Bluetooth iBeacons you
              can monitor your presence inside your office.
              <br />
              <br />
              Application is not yet released. Service is available only for
              selected beta testers.
              <br />
              Mobile application will be available soon.
            </p>
            <ErrorBoundary>
              <ClientOnlySuspense>
                <SignInAndRegisterButtons />
              </ClientOnlySuspense>
            </ErrorBoundary>
          </div>
          <div className="w-32 flex-auto">
            <Image
              src={heroImage}
              layout="responsive"
              className="rounded-md drop-shadow-xl"
              alt="Image with workstation and board with text Get Shit Done"
              priority={true}
            />
          </div>
        </div>
      </section>
      <section className="flex w-[200px] flex-col gap-4">
        <a
          href="https://www.apple.com/app-store/"
          className="block h-full w-full min-w-[200px]"
        >
          <Image
            alt="Appstore badge"
            src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
            className="block align-top"
            width="119.66407"
            height="40"
            layout="responsive"
          />
        </a>
        <a
          href="https://play.google.com/store"
          className="block  h-full w-full min-w-[200px]"
        >
          <Image
            alt="Google Play badge"
            src="/Google_Play-Badge-Logo.svg"
            className="block align-top"
            width="2999px"
            height="891px"
            layout="responsive"
          />
        </a>
      </section>
    </div>
  );
}

export default Index;
